import { z } from 'zod'

import { CustomFieldTypeSchema } from '../generated/trpc/schemas'
import { CustomFieldModel, CustomFieldModelResponse } from '../generated/zod'

export const SEARCH_FIELD_DELIMITER = '||'
export type CustomField = z.infer<typeof CustomFieldModel>
export const generateSearchField = (
  fieldOptionsTr: CustomField['fieldOptionsTr'],
) => {
  const searchField = Object.values(fieldOptionsTr).reduce(
    (arr, cur) => `${arr}${SEARCH_FIELD_DELIMITER}${cur}`,
    '',
  )

  return searchField
}

export const SearchCustomFieldInputModel = z.object({
  id: z.number().optional(),
  page: z.number().min(1).default(1),
  pageSize: z.number().min(1).max(100).default(10),
  orderBy: z
    .enum(['fieldType', 'priority', 'createdAt', 'updatedAt'])
    .default('createdAt'),
  orderDirection: z.enum(['asc', 'desc']).default('desc'),
  searchField: z.string().optional(),
  type: CustomFieldTypeSchema.optional(),
  isActive: z.preprocess(
    (arg) => (typeof arg === 'string' ? arg === 'true' : arg),
    z.boolean().optional(),
  ),
})
export type SearchCustomFieldInput = z.infer<typeof SearchCustomFieldInputModel>

export const CreateCustomFieldModel = CustomFieldModel.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  active: true,
  searchField: true,
}).transform((data) => {
  const searchField = generateSearchField(data.fieldOptionsTr)
  return { ...data, searchField }
})
export type CreateCustomField = z.infer<typeof CreateCustomFieldModel>

export const UpdateCustomFieldModel = CustomFieldModel.omit({
  createdAt: true,
  updatedAt: true,
}).partial()

export type UpdateCustomField = z.infer<typeof UpdateCustomFieldModel>

export const SearchCustomFieldResponseModel = z.object({
  data: z.array(CustomFieldModelResponse),
  pagination: z.object({
    total: z.number(),
    page: z.number(),
    pageSize: z.number(),
    totalPages: z.number(),
  }),
})

export type SearchCustomFieldResponse = z.infer<
  typeof SearchCustomFieldResponseModel
>
